import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearSession,
  openSnackBar,
  setAuthUser,
  setSession,
} from "../store/ducks/app";

const useApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const session = useSelector((state: any) => state.app.session);
  const authUser = useSelector((state: any) => state.app.authUser);
  const modalOpen = useSelector((state: any) => state.app.modalOpen);
  const snackBarOpen = useSelector((state: any) => state.app.snackBarOpen);
  const language = useSelector((state: any) => state.app.language);

  return {
    session,
    authUser,
    modalOpen,
    snackBarOpen,
    language,
    setSession: (session: any) => dispatch(setSession(session)),
    setAuthUser: (user: any) => dispatch(setAuthUser(user)),
    clearSession: () => {
      dispatch(clearSession());
      navigate("/");
    },
    showConfirm: (message: string) =>
      dispatch(openSnackBar(message, "success")),
    showError: (error: any) => dispatch(openSnackBar(error, "error")),
    navigateTo: (url: string) => navigate(url),
  };
};

export default useApp;
