import { ThemeOptions } from "@mui/material/styles";
import GothamBook from "../assets/fonts/GothamBook.ttf";
import GothamMedium from "../assets/fonts/GothamMedium.ttf";
import GothamBold from "../assets/fonts/GothamBold.ttf";

const lightThemeOptions: ThemeOptions = {
  direction: "ltr",
  palette: {
    primary: {
      light: "#ab5357",
      main: "#97282D",
      dark: "#691c1f",
    },
    secondary: {
      light: "#33bfff",
      main: "#00b0ff",
      dark: "#007bb2",
    },
    background: {
      paper: "#fff",
      default: "#fafafa",
    },
    text: {
      primary: "#8B0F14",
      secondary: "#0B416A",
    },
  },
  typography: {
    fontFamily: "Gotham, Arial, sans-serif",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Gotham,Arial,sans-serif';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Gotham'), local('Gotham-Regular'), url(${GothamBook}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
				@font-face {
          font-family: 'Gotham';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Gotham'), local('Gotham-Medium'), url(${GothamMedium}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
				@font-face {
          font-family: 'Gotham';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Gotham'), local('Gotham-Bold'), url(${GothamBold}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
};

export default lightThemeOptions;
