import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import ErrorBoundary from "./components/UI/ErrorBoundary";
import MainLoader from "./components/UI/MainLoader";
import MainModal from "./components/UI/MainModal";
import MainSnackbar from "./components/UI/MainSnackbar";
import useApp from "./hooks/useApp";

const SiteInterface = lazy(() => import("./containers/SiteInterface"));

const App: React.FC = () => {
  const { modalOpen, snackBarOpen } = useApp();

  return (
    <ErrorBoundary>
      <Suspense fallback={<MainLoader />}>
        <Routes>
          <Route path="/*" element={<SiteInterface />} />
        </Routes>
      </Suspense>

      {modalOpen && <MainModal />}
      {snackBarOpen && <MainSnackbar />}
    </ErrorBoundary>
  );
};

export default App;
