import { Directions, Languages } from "./../../constants/enums";

// actions
const SET = "app/SET";
const CLEAR = "app/CLEAR";
const Auth_USER = "app/Auth_USER";
const SET_DIRECTION = "app/SET_DIRECTION";
const SET_LANGUAGE = "app/SET_LANGUAGE";
const OPEN_MODAL = "app/OPEN_MODAL";
const CLOSE_MODAL = "app/CLOSE_SNACKBAR";
const OPEN_SNACKBAR = "app/OPEN_SNACKBAR";
const CLOSE_SNACKBAR = "app/CLOSE_MODAL";

const DEFAULT_STATE = {
  direction: Directions.LTR,
  language: Languages.ENGLISH,
  modalOpen: false,
  modalContent: null,
  snackBarOpen: false,
  snackBarSeverity: "info",
  snackBarContent: null,
  snackBarPersist: false,
  session: null,
  authUser: null,
};

// reducer
const appReducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET_DIRECTION:
      return Object.assign({}, state, { direction: action.direction });
    case SET_LANGUAGE:
      return Object.assign({}, state, { language: action.language });
    case OPEN_MODAL:
      return Object.assign({}, state, {
        modalOpen: true,
        modalContent: action.modalContent,
      });
    case CLOSE_MODAL:
      return Object.assign({}, state, {
        modalOpen: false,
        modalContent: null,
      });
    case OPEN_SNACKBAR:
      return Object.assign({}, state, {
        snackBarOpen: true,
        snackBarContent: action.snackBarContent,
        snackBarSeverity: action.snackBarSeverity,
        snackBarPersist: action.persist ? action.persist : false,
      });
    case CLOSE_SNACKBAR:
      return Object.assign({}, state, {
        snackBarOpen: false,
        snackBarContent: null,
        snackBarSeverity: "info",
      });
    case SET:
      return Object.assign({}, state, { session: action.session });
    case CLEAR:
      return Object.assign({}, state, { session: null, authUser: null });
    case Auth_USER:
      return Object.assign({}, state, { authUser: action.user });
    default:
      return state;
  }
};

export default appReducer;

// action creators
export const setDirection = (direction: string) => ({
  direction,
  type: SET_DIRECTION,
});

export const setLanguage = (language: string) => ({
  language,
  type: SET_DIRECTION,
});

export const openModal = (modalContent: any) => ({
  modalContent,
  type: OPEN_MODAL,
});

export const closeModal = () => ({ modalContent: null, type: CLOSE_MODAL });

export const openSnackBar = (
  snackBarContent: string,
  snackBarSeverity: string,
  persist?: boolean
) => ({ snackBarContent, snackBarSeverity, persist, type: OPEN_SNACKBAR });

export const closeSnackBar = () => ({
  snackBarContent: null,
  type: CLOSE_SNACKBAR,
});

export const setSession = (session: any) => ({ session, type: SET });

export const clearSession = () => ({ type: CLEAR });

export const setAuthUser = (user: any) => ({ user, type: Auth_USER });
