// actions
const SET = "concepts/SET";
const SET_SEARCH_TEXT = "concepts/SET_SEARCH_TEXT";
const SET_SELECTED = "concepts/SET_SELECTED";
const SET_BRANCHES = "concepts/SET_BRANCHES";

const DEFAULT_STATE = {
  listing: [],
  searchText: "",
  selected: "",
  branches: [],
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_SEARCH_TEXT:
      return Object.assign({}, state, { searchText: action.searchText });
    case SET_SELECTED:
      return Object.assign({}, state, { selectedConcept: action.concept });
    case SET_BRANCHES:
      return Object.assign({}, state, { conceptBranches: action.branches });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });

export const setSearchText = (searchText: string) => ({
  searchText,
  type: SET_SEARCH_TEXT,
});

export const setSelected = (single: any) => ({ single, type: SET_SELECTED });

export const setBranches = (branches: any) => ({
  branches,
  type: SET_BRANCHES,
});
